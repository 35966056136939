import anime from 'animejs/lib/anime.es.js';

const PostPageAnimations = () =>{
    const postEnterAnimation = (enter, node) => {
        let relativeEl = document.querySelector('.pjs-post-text');
        relativeEl.style.transform = 'translateY(50%) scale(1.2)';
        relativeEl.style.boxShadow = '0 -25px 30px -12px rgba(0, 0, 0, 0.25)';
        let textElms = document.querySelectorAll('.pjs-post-text .content-title, .pjs-post-text .content-body');
        textElms.forEach((elm)=>{
            elm.style.opacity = '0';

        })
        let postContainerIn = anime.timeline({
            
            duration: 2600,
            easing: "easeInOutQuad",
		});
        postContainerIn.add({
            targets: '.pjs-post-text',
            keyframes: [
                {
                    scale:1.2,
                    translateY: '30%',
                },
                {
                    scale:1,
                    translateY: '0%',
                },
                {
                    scale:1,
                    boxShadow:'0 0 0 0 rgba(0, 0, 0, 0.25)',
                },
            ],
		});
        postContainerIn.add({
            targets: '.pjs-post-text .content-title, .pjs-post-text .content-body',
            keyframes: [
                {
                    opacity:0,
                },
                {
                    opacity:1,
                },
            ],
		},'-=1800');
    };
    return {postEnterAnimation};
}
export default PostPageAnimations;