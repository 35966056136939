import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

import TransitionLink from "gatsby-plugin-transition-link";

import GenericPageTransition from '../animations/GenericPageTransition'

const PostCard = ({ post }) => {
    const url = `/projects/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    let {growLeftToRight, shrinkLeftToRight} = GenericPageTransition()
    
    return (
        <TransitionLink 
            to={url} 
            exit={{
                trigger: ({ exit, node }) => growLeftToRight(exit, node),
                length: 1
            }}
            entry={{
                trigger: ({ exit, node }) => shrinkLeftToRight(exit, node),
                delay: 1
            }}
            className="post-card"
        >
            <header className="post-card-header tw-absolute tw-z-20 tw-h-full tw-w-full">
                {post.feature_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.feature_image})` ,
                    }}></div>}
                {/* {post.tags && 
                <div className="post-card-tags"> 
                    <Tags post={post} visibility="public" autolink={false} />
                </div>
                } */}
                {post.featured && <span>Featured</span>}
            </header>
            <section className="post-card-text">
                <div>
                    {post.excerpt}
                </div>
                <h2 className="post-card-title">{post.title}</h2>
            </section>
            {/* <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {post.primary_author.profile_image ?
                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                        }
                    </div>
                    <span>{ post.primary_author.name }</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer> */}
        </TransitionLink>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
