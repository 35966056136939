import React, {useEffect, useState} from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

import anime from 'animejs/lib/anime.es.js';

// Styles
import '../../styles/app.css'
import '../../styles/hamburgers.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const SideNav = ({ site, navOpen, handleNavClicked }) => {
    
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null;
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null;
    const [scrolledPastSplash, setScrolledPastSplash] = useState(false);

    const fadeIn = () => {
        anime({
            targets: '.pjs-side-nav',
            opacity:1,
            duration: 500,
            easing:'easeOutCirc'
		});
    };

    const fadeOut = () => {
        anime({
            targets: '.pjs-side-nav',
            opacity:0,
            duration: 500,
            easing:'easeOutCirc'
		});
    };

    useEffect(() => {
        if(navOpen){
            fadeIn();
        }else{
            fadeOut();
        }
    }, [navOpen]);

    return (
        <>
            <nav className={`pjs-side-nav tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-black tw-fixed tw-right-0 tw-bg-white tw-h-screen ${(navOpen ? 'tw-z-40': '')} lg:tw-z-0 tw-w-full lg:tw-w-1/6`}>
                    {/* The navigation items as setup in Ghost */}
                    <Navigation data={site.navigation} navClass="tw-text-black tw-px-4 tw-text-6xl tw-leading-loose" />
                    { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon tw-text-black" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
                    { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon tw-text-black" src="/images/icons/facebook.svg" alt="Facebook" /></a>}
                
            </nav>
        </>
    )
}

export default SideNav
