import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

import anime from 'animejs/lib/anime.es.js';

// Styles
import '../../styles/app.css'
import Header from './Header'
import SideNav from './SideNav'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node

    const [openNavMenu, setOpenNavMenu] = useState(false);

    const handleToggleSideNav = () => {
        setOpenNavMenu(!openNavMenu);
    }

    const shrinkToLeft = () => {
        console.log('desu');
        anime({
            targets: '.site-main, .site-main-home, .viewport-bottom',
            scale:'.66',
            translateY:'-25%',
            duration: 500,
            easing:'easeOutCirc'
		});
    };

    const expandToRight = () => {
        anime({
            targets: '.site-main, .site-main-home, .viewport-bottom',
            scale:'1',
            translateY:'0%',
            duration: 500,
            easing:'easeOutCirc'
		});
    };

    useEffect(() => {
        if(openNavMenu){
            shrinkToLeft();
        }else{
            expandToRight();
        }
    }, [openNavMenu]);

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className={"viewport " + (isHome ? 'site-home' : '' )}>

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    {/* <header className="site-head" style={{ ...site.cover_image && { backgroundImage: `url(${site.cover_image})` } }}> */}
                    <Header site={site} navOpen={openNavMenu} handleNavClicked={handleToggleSideNav}/>
                    <SideNav site={site} navOpen={openNavMenu} handleNavClicked={handleToggleSideNav}/>

                    <main className={`tw-z-40 ${(!isHome ? 'site-main' : 'site-main-home' )}`} onClick={()=>{setOpenNavMenu(false)}}>
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom tw-z-40">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">{site.title}</Link> © {new Date().getFullYear()}
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
