import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

import TransitionLink from "gatsby-plugin-transition-link";

import PostPageAnimations from '../animations/PostPageAnimations'
import HomePageAnimations from '../animations/HomePageAnimations'
import GenericPageTransition from '../animations/GenericPageTransition'

const PostSlide = ({ post }) => {
    const url = `/projects/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    let {homeExitAnimation} = HomePageAnimations()
    let {postEnterAnimation} = PostPageAnimations()
    let {growLeftToRight, shrinkLeftToRight} = GenericPageTransition()
    
    return (
        <TransitionLink 
            to={url} 
            exit={{
                trigger: ({ exit, node }) => growLeftToRight(exit, node),
                length: 1
            }}
            entry={{
                trigger: ({ exit, node }) => shrinkLeftToRight(exit, node),
                delay: 1
            }}
            className="tw-block tw-h-full tw-w-full tw-no-underline tw-relative pjs-slide-content tw-mx-auto"
        >
            
        
            {post.feature_image &&
                <img src={ post.feature_image } alt={ post.title } className="post-slide-image tw-h-full tw-w-full tw-relative"/>
            }
            <section className="post-slide-header tw-absolute tw-z-20 tw-bottom-12 tw-left-0">
                <h2 className="post-slide-title">{post.title}</h2>
            </section>
            {/* <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {post.primary_author.profile_image ?
                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                        }
                    </div>
                    <span>{ post.primary_author.name }</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer> */}
        </TransitionLink>
    )
}

PostSlide.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostSlide
