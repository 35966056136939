import React, {useEffect, useState} from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'


// Styles
import '../../styles/app.css'
import '../../styles/hamburgers.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const Header = ({ site, navOpen, handleNavClicked }) => {
    
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null;
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null;
    const [scrolledPastSplash, setScrolledPastSplash] = useState(false);

    useEffect(() => {
        const pageScrolled = (event)=>{
            let elementTarget = document.getElementById("splash");
            setScrolledPastSplash(window.scrollY > 20);
        };
        window.addEventListener('scroll', pageScrolled)
        return () => {
            window.removeEventListener('scroll', pageScrolled);
        };
    }, []);

    return (
        <>
            <header className={"site-head trendy tw-bg-pjsPink position tw-z-50 tw-fixed tw-w-screen " + ( scrolledPastSplash ? 'scrolled' : '')}>
                <div className="site-mast tw-w-full tw-px-12 tw-flex tw-flex-wrap tw-justify-center">
                    <nav className="site-nav">
                            <h1 className={"fancy-title-text tw-transition-all tw-duration-500 " +  ( scrolledPastSplash && !navOpen ? 'tw-opacity-0' : 'lg:tw-opacity-100')}>{site.title}</h1>
                    </nav>
                    <div className="tw-absolute tw-top-0 tw-right-0 lg:tw-top-12 lg:tw-right-12 tw-z-50">
                        <button className={"hamburger hamburger--squeeze" + (navOpen ? ' is-active' : '')} type="button" onClick={()=>{ handleNavClicked() }}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                
            </header>
        </>
    )
}

export default Header
