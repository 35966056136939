import anime from 'animejs/lib/anime.es.js';

const HomePageAnimations = () =>{
    const homeExitAnimation = (exit, node) => {
        let tl = anime.timeline({
			easing: "linear",
		});
		tl.add({
			targets: '.post-slide-header, .swiper-pagination, .swiper-button-next, .swiper-button-prev',
            opacity: 0,
            duration: 0,
		},0)

		tl.add({
			targets: '.pjs-swipper .swiper-slide-active .pjs-slide-content .post-slide-image',
            keyframes: [
                {
                    top: '50%',
                    translateY: '-50%', 
                    left: '50%',
                    translateX: '-50%', 
                },
                {
                    width:1398,
                    height:500,
                },
            ],
            
			duration: 500,
		},0)
		tl.add({
			targets: '.pjs-swipper',
            height:'500px',
            paddingTop:'2wh',
			duration: 500,
		},0)
		tl.add({
			targets: '.pjs-home-content',
            opacity: 0,
			duration: 1000,
		}, 0)
        
    };
    return {homeExitAnimation};
}
export default HomePageAnimations;