import anime from 'animejs/lib/anime.es.js';

const GenericPageTransition = () =>{
    const growLeftToRight = (exit, node) => {
        let slide = anime({
            targets: '.slide-transition',
            width:'100vw',
            duration: 500
		});
    };
    const shrinkLeftToRight = (exit, node) => {
        anime.set('.slide-transition', { 
            width:'100vw',
            translateX:'0%',
        });
        let slide = anime({
            easing:'easeInOutExpo',
            targets: '.slide-transition',
            width:'0vw',
            left:'100%',
            opacity:0,
            duration: 1000,
		});
    };
    return {growLeftToRight, shrinkLeftToRight};
}
export default GenericPageTransition;